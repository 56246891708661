import { Button, Flex, Layout, Menu, MenuProps, theme } from 'antd';
import { STORAGE } from 'constants/storage.const';
import { Suspense, useEffect, useState } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import storage from 'utils/LocalStorage';
import { useLocation } from 'react-router-dom';

const { Header, Content, Sider } = Layout;

const items2: MenuProps['items'] = [
  { key: '/admin/test-results', label: 'Test Results' },
  { key: '/admin/register', label: 'Add new account' },
];
const LayoutAdmin = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const navigate = useNavigate();

  const { pathname } = useLocation();

  const [openKey, setOpenKey] = useState('/admin/test-results');

  const fullName = storage.get(STORAGE.FULL_NAME);

  useEffect(() => {
    if (pathname.includes('register')) {
      setOpenKey('register');
    } else {
      setOpenKey('test-results');
    }
  }, [pathname]);

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header
        style={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#F9F9F9',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Flex
          gap={100}
          align="center"
        >
          <Link to="/admin/test-results">
            <img
              width={80}
              height={70}
              alt=""
              src="/assets/logo.png"
            />{' '}
          </Link>

          {/* {fullName && <span>Hi {fullName}</span>} */}
        </Flex>
        <Button
          type="primary"
          onClick={() => {
            navigate('/login');
            storage.clear();
          }}
          className="text-black hover:!text-black"
        >
          Logout
        </Button>
      </Header>
      <Layout>
        <Sider
          width={200}
          style={{ background: colorBgContainer }}
        >
          <Menu
            mode="inline"
            defaultSelectedKeys={[openKey]}
            selectedKeys={[openKey]}
            defaultOpenKeys={['sub1']}
            style={{ height: '100%', borderRight: 0, paddingTop: 30 }}
            items={items2}
            onDeselect={(data) => {
              navigate(data.key);
            }}
            onSelect={(data) => {
              navigate(data.key);
            }}
          />
        </Sider>
        <Layout style={{ padding: '0 24px 24px' }}>
          <Content
            style={{
              padding: 24,
              margin: '16px 0  0 0',
              minHeight: 'fit-content',
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
          >
            <Suspense fallback={<div />}>
              <Outlet />
            </Suspense>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default LayoutAdmin;
