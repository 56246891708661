import { BEGINNER_TOPIC } from "./beginner";
import { INTERMEDIATE_TOPIC } from "./intermediate";

export const QUIZ_TEST = [
  {
    code: "beginner-test-100", // url
    title: "Beginner IELTS Placement Test 100'",
    test: BEGINNER_TOPIC,
    audioUrl: "/assets/MC2-3-4-Placement-Test.mp3",
  },
  {
    code: "test-4-skill", // url
    title: "Intermediate IELTS Placement Test",
    test: INTERMEDIATE_TOPIC,
    audioUrl: "/assets/intermediate-listening-Placement-Test.mp3",
  },
];
