import { IAnswerType, ITestsModel } from "../model";
import { SPEAKING } from "./beginner";

export const LISTENING: ITestsModel[] = [
  {
    id: 2,
    example: ` 
    <h2 style="font-weight: 600; font-size:24px">Questions 1-5</h2>
    <p>Choose the correct letter, A, B or C.</p>
    </br>
    <h3 style="font-weight: 600; font-size:16px">Volunteers at Greenfield City Arts Festival</h3>`,
    time: 20,
    part: [
      {
        id: 1,
        question: "What is special about the Greenfield City Arts Festival?",
        answer: "",
        correctAnswer: "It was the first to invite overseas artists.",
        type: IAnswerType.RADIO,
        answerList: [
          "It is held in a beautiful university campus.",
          "It is the oldest arts festival in the country.",
          "It was the first to invite overseas artists.",
        ],
      },
      {
        id: 2,
        question:
          "What is the main purpose of this year’s Greenfield City Arts Festival?",
        answer: "",
        correctAnswer: "To provide local residents with entertainment.",
        type: IAnswerType.RADIO,
        answerList: [
          "To provide local residents with entertainment.",
          "To attract tourists to the Greenfield City area.",
          "To raise funds for the City Hall to be improved.",
        ],
      },
      {
        id: 3,
        question: "More volunteers are needed this year in",
        answer: "",
        correctAnswer: "the information kiosks.",
        type: IAnswerType.RADIO,
        answerList: [
          "the children’s areas.",
          "the information kiosks.",
          "the concert halls.",
        ],
      },
      {
        id: 4,
        question: "What type of work will the volunteers have to do?",
        answer: "",
        correctAnswer: "check tickets at venues",
        type: IAnswerType.RADIO,
        answerList: [
          "give out maps of the Festival",
          "check tickets at venues",
          "do general office work",
        ],
      },
      {
        id: 5,
        question:
          "What do the volunteers still have to do to get their volunteer card?",
        answer: "",
        correctAnswer: "sign on the back of the card",
        type: IAnswerType.RADIO,
        answerList: [
          "sign on the back of the card",
          "pay a small fee for the card",
          "have a photograph taken",
        ],
      },
      {
        id: 6,
        question: `
         <h2 style="font-weight: 600; font-size:24px">Questions 6-10</h2>
        <p>Label the map below.</p>
        </br>
        <h3 style="font-weight: 600; font-size:14px">Write the correct letter <strong>A–G</strong> in boxes  <strong>6–10</strong> on your answer sheet.</h3>
        </br>
        <img src="/assets/listening/intermediate-listening-6-10.png" style="padding: 24px"/>
        </br>
        </br>
        </br>
        </br>
        Toilets: 
        `,
        answer: "",
        correctAnswer: "B",
        type: IAnswerType.DROPDOWN,
        answerList: ["A", "B", "C", "D", "E", "F", "G"],
      },
      {
        id: 7,
        question: "Water fountain",
        answer: "",
        correctAnswer: "E",
        type: IAnswerType.DROPDOWN,
        answerList: ["A", "B", "C", "D", "E", "F", "G"],
      },
      {
        id: 8,
        question: "Information tent",
        answer: "",
        correctAnswer: "D",
        type: IAnswerType.DROPDOWN,
        answerList: ["A", "B", "C", "D", "E", "F", "G"],
      },
      {
        id: 9,
        question: "Ticket office",
        answer: "",
        correctAnswer: "G",
        type: IAnswerType.DROPDOWN,
        answerList: ["A", "B", "C", "D", "E", "F", "G"],
      },
      {
        id: 10,
        question: "Food stalls",
        answer: "",
        correctAnswer: "F",
        type: IAnswerType.DROPDOWN,
        answerList: ["A", "B", "C", "D", "E", "F", "G"],
      },
    ],
  },
  {
    id: 3,
    example: `<p style="font-weight: 600; font-size:24px"><strong>University Environmental Sustainability Program</strong></p>`,
    part: [
      {
        id: 11,
        question:
          "What problem do Greg and Tanya identify with the University’s solar energy program?",
        answer: "",
        correctAnswer: "it is expensive to set up",
        type: IAnswerType.RADIO,
        answerList: [
          "it is expensive to set up",
          "it takes up a lot of space",
          "it lacks government support",
        ],
      },
      {
        id: 12,
        question:
          "What do they agree will be the main benefit of the solar energy program?",
        answer: "",
        correctAnswer: "conducting new research",
        type: IAnswerType.RADIO,
        answerList: [
          "saving on electricity bills",
          "reducing carbon emissions",
          "conducting new research",
        ],
      },
      {
        id: 13,
        question: "What reservation does Greg have about the new building?",
        answer: "",
        correctAnswer: "the practicality of the design",
        type: IAnswerType.RADIO,
        answerList: [
          "the source of the materials",
          "the practicality of the design",
          "the length of the project",
        ],
      },
      {
        id: 14,
        question: "What does Tanya appreciate most about the building?",
        answer: "",
        correctAnswer: "the relationship between form and function",
        type: IAnswerType.RADIO,
        answerList: [
          "the availability of more teaching space",
          "the relationship between form and function",
          "the enhancement of this part of the campus",
        ],
      },
      {
        id: 15,
        question:
          "Which environmental issue do the bottle refill stations address?",
        answer: "",
        correctAnswer: "Waste",
        type: IAnswerType.RADIO,
        answerList: ["Recycling", "Waste", "water conservation"],
      },
      {
        id: 16,
        question: "What does Greg dislike about the bottle refill stations?",
        answer: "",
        correctAnswer: "the water temperature",
        type: IAnswerType.RADIO,
        answerList: [
          "the water temperature",
          "the long queues",
          "the hygiene aspect",
        ],
      },
      {
        id: 17,
        question:
          "What does Tanya suggest regarding the Green Office initiative?",
        answer: "",
        correctAnswer: "it should be extended",
        type: IAnswerType.RADIO,
        answerList: [
          "it should be publicised more",
          "it should be cancelled",
          "it should be extended",
        ],
      },
      {
        id: 18,
        question: `<h1 style="font-weight: 600; font-size:24px">Questions 18-20</h1>
    <p>Complete the flow chart below.</p>
    <p>Choose <strong>THREE</strong> answers from the box and write the correct letter, A–F, in boxes 18–20 on your answer sheet.</p>
        </br>
     </br>
    <p>Established a university <strong>18..............</strong> to develop a sustainability plan.</p>
    <p>Revised guidelines for construction of new buildings.</p>
    <p>Appointed a new <strong>19..............</strong> to implement targets on campus.</p>
    <p>Recruited personnel for the <strong>20..............</strong> which will provide leadership in sustainable research.</p>
    <p>Carried out research projects on sustainability.</p>

      </br>
    <h3 style="font-weight: 600; font-size:16px">Choose from the following options:</h3>
    <p>
        <strong>A.</strong> student body<br>
        <strong>B.</strong> institute<br>
        <strong>C.</strong> designer<br>
        <strong>D.</strong> professor<br>
        <strong>E.</strong> energy manager<br>
        <strong>F.</strong> steering committee
    </p>`,
        answer: "",
        correctAnswer: "F",
        type: IAnswerType.DROPDOWN,
        answerList: ["A", "B", "C", "D", "E", "F"],
      },
      {
        id: 19,
        question: ``,
        answer: "",
        correctAnswer: "E",
        type: IAnswerType.DROPDOWN,
        answerList: ["A", "B", "C", "D", "E", "F"],
      },
      {
        id: 20,
        question: ``,
        answer: "",
        correctAnswer: "B",
        type: IAnswerType.DROPDOWN,
        answerList: ["A", "B", "C", "D", "E", "F"],
      },
    ],
  },
];

export const READING: ITestsModel[] = [
  {
    id: 1,
    example: `<div>
      <h1 style="font-weight: 600; font-size:24px">Local Museums</h1>
    <p>Read the text below and answer Questions 1-7.</p>
    </br>

    <h2 style="font-weight: 600; font-size:16px">A. Whittlesey Museum</h2>
    <p>The museum is located in the Old Town Hall, which was originally built to house horse-drawn fire engines. It has eight rooms, and the exhibits cover topics such as archive photographs, costume, domestic life, and local celebrities.</p>
     </br>
    
    <h2 style="font-weight: 600; font-size:16px">B. Octavia Hill's Birthplace House</h2>
    <p>Built in 1740, this is the birthplace of pioneer social reformer Octavia Hill, who was active in the late 19th and early 20th centuries in social housing and the arts, as well as in conservation issues. Visitors are taken on a guided tour and are then free to explore the gardens.</p>
 </br>
    <h2 style="font-weight: 600; font-size:16px">C. Chatteris Museum</h2>
    <p>The old market town of Chatteris was largely rebuilt after two serious fires in 1706 and 1864 destroyed many of the town's ancient buildings. The museum's exhibits illustrate traditional aspects of the life of local farmers as well as the railway boom of the 19th century. The museum has a touch-screen kiosk which contains over 9,000 historic photographs and texts, reproductions of which can be made on request.</p>
 </br>
    <h2 style="font-weight: 600; font-size:16px">D. March and District Museum</h2>
    <p>Located in the middle of the market town of March, the museum is housed in a former school built in 1851. Its wide-ranging collections include reconstructions of an early 20th-century kitchen, sitting room, and nursery. There is also an interesting display of historic cameras and radios, and a medal which was awarded to train driver Ben Gimbert for his bravery in preventing loss of life when a train full of explosives caught fire in 1944.</p>
 </br>
    <h2 style="font-weight: 600; font-size:16px">E. Wisbech and Fenland Museum</h2>
    <p>This 19th-century gem holds collections from around the world including Ancient Egypt. Its library, which is open to the public on the first Saturday of each month, contains the manuscript of <i>Great Expectations</i> by the 19th-century novelist Charles Dickens, and the Research Room can be booked for researching local records.</p>
 </br>
    <h3 style="font-weight: 600; font-size:16px">Questions 1-7</h3>
    <p>Look at the five descriptions of museums, A-E, in one area of England.</p>
    <p>For which museum are the following statements true?</p>
    <p>Write the correct letter, A-E, in boxes 1-7 on your answer sheet.</p>
    <p><strong>NB:</strong> You may use any letter more than once.</p>
      </div>`,
    time: 20,
    part: [
      {
        id: 1,
        question:
          "There are exhibits related to the history of agriculture in the region.",
        answer: "",
        correctAnswer: "C",
        type: IAnswerType.TEXT_AREA,
        answerList: [],
      },
      {
        id: 2,
        question:
          "Equipment for putting out fires used to be kept in this building.",
        answer: "",
        correctAnswer: "A",
        type: IAnswerType.TEXT_AREA,
        answerList: [],
      },
      {
        id: 3,
        question:
          "You can find information on the rise of one type of transport.",
        answer: "",
        correctAnswer: "C",
        type: IAnswerType.TEXT_AREA,
        answerList: [],
      },
      {
        id: 4,
        question: "There are things to see both inside and outside.",
        answer: "",
        correctAnswer: "B",
        type: IAnswerType.TEXT_AREA,
        answerList: [],
      },
      {
        id: 5,
        question:
          "It is possible to obtain copies of old pictures and documents.",
        answer: "",
        correctAnswer: "C",
        type: IAnswerType.TEXT_AREA,
        answerList: [],
      },
      {
        id: 6,
        question:
          "On certain days you can see an original work by a writer of fiction.",
        answer: "",
        correctAnswer: "E",
        type: IAnswerType.TEXT_AREA,
        answerList: [],
      },
      {
        id: 7,
        question:
          "Someone who was interested in environmental matters lived here for a time.",
        answer: "",
        correctAnswer: "B",
        type: IAnswerType.TEXT_AREA,
        answerList: [],
      },
    ],
  },
  {
    id: 2,
    example: `<div>
    <h1 style="font-weight: 600; font-size:24px">Young Fashion Designer UK Competition</h1>
    <p>Read the text below and answer Questions 8-14.</p>
    </br>

    <p><strong>Young Fashion Designer UK</strong> is an exciting national competition which aims to showcase and promote the exceptional work achieved by students studying courses in textile design, product design, and fashion throughout the UK.</p>
</br>
    <p>The competition is designed for students to enter the coursework they are currently working on rather than specifically producing different pieces of work. If you would like to add to your coursework, that is for you and your teacher to decide.</p>
</br>
    <p>You can apply independently or through your school/college. To enter please ensure you follow these steps:</p>
    <p>1. Provide three A3 colour copies from your design folder. <br>
       You must include: <br>
       - initial ideas about the clothing <br>
       - a close-up photograph of the front and back view of the finished clothing. 
    </p>
    <p>2. Please label each sheet clearly with your name and school (on the back).</p>
    <p>3. Print off a copy of your registration form and attach it to your work.</p>
    <p>4. Post your entry to the Young Fashion Designer Centre.</p>
</br>
    <p>Once the entry deadline has passed, the judges will select the shortlist of students who will be invited to the Finals. You will be notified if you are shortlisted. You will need to bring originals of the work that you entered. Each finalist will have their own stand consisting of a table and tabletop cardboard display panels. Feel free to add as much creativity to your stand as possible. Some students bring tablets/laptops with slideshows or further images of work but it should be emphasised that these may not necessarily improve your chances of success.</p>
</br>
    <p>The judges will assess your work and will ask various questions about it. They will look through any supporting information and the work you have on display before coming together as a judging panel to decide on the winners. You are welcome to ask the judges questions. In fact, you should make the most of having experts on hand!</p>
</br>
    <p>There are 1st, 2nd, and 3rd prize winners for each category. The judges can also decide to award special prizes if the work merits this. The 1st, 2nd, and 3rd place winners will receive a glass trophy and prize from a kind donor.</p>
</br>
    <h3 style="font-weight: 600; font-size:16px">Questions 8-14</h3>
    <p>Do the following statements agree with the information given in the text on passage 2?</p>
    <p>In boxes 8-14 on your answer sheet, write:</p>
    <p><strong>TRUE</strong> if the statement agrees with the information</p>
    <p><strong>FALSE</strong> if the statement contradicts the information</p>
    <p><strong>NOT GIVEN</strong> if there is no information on this</p>
    </div>`,
    part: [
      {
        id: 8,
        question:
          "Participants are required to create a new item of clothing for the Young Fashion Designer UK competition.",
        answer: "",
        correctAnswer: "False",
        type: IAnswerType.DROPDOWN,
        answerList: ["True", "False", "Not given"],
      },
      {
        id: 9,
        question:
          "Participants must send information about the thoughts that led to the item they are entering for the competition.",
        answer: "",
        correctAnswer: "True",
        type: IAnswerType.DROPDOWN,
        answerList: ["True", "False", "Not given"],
      },
      {
        id: 10,
        question: "The shortlist will consist of a fixed number of finalists.",
        answer: "",
        correctAnswer: "Not given",
        type: IAnswerType.DROPDOWN,
        answerList: ["True", "False", "Not given"],
      },
      {
        id: 11,
        question:
          "Finalists can choose how to present their work  to the judges on their stand. ",
        answer: "",
        correctAnswer: "True",
        type: IAnswerType.DROPDOWN,
        answerList: ["True", "False", "Not given"],
      },
      {
        id: 12,
        question:
          "It is strongly recommended that finalists support their entry with additional photographs.",
        answer: "",
        correctAnswer: "False",
        type: IAnswerType.DROPDOWN,
        answerList: ["True", "False", "Not given"],
      },
      {
        id: 13,
        question:
          "Questions that the students ask the judges may count towards the final decisions.",
        answer: "",
        correctAnswer: "Not given",
        type: IAnswerType.DROPDOWN,
        answerList: ["True", "False", "Not given"],
      },
      {
        id: 14,
        question:
          "Extra prizes may be awarded depending on the standard of the entries submitted.",
        answer: "",
        correctAnswer: "True",
        type: IAnswerType.DROPDOWN,
        answerList: ["True", "False", "Not given"],
      },
    ],
  },
  {
    id: 3,
    example: `<div>
    <h1 style="font-weight: 600; font-size:24px">School of Design COURSE GUIDELINES</h1>
    <p>Now read the information below and answer Questions 15-20.</p>
</br>
    <h2 style="font-weight: 600; font-size:16px">2.1 Assignments</h2>
    <p>Coursework assignments will involve the production of an artefact (something shaped by human beings rather than by nature) OR an investigation of some kind followed by a report. This is to demonstrate the relevance of your study to society today. If you opt to produce an artefact, (e.g. a working model or piece of machinery) you will also be expected to provide some written explanation of how and why you produced it.</p>
</br>
    <p>You need to follow these steps:</p>
    <p>1. Find out precisely what is expected of you. Talk to your tutor and refer to the syllabus document.<br>
       2. Be aware of what skills and abilities you must demonstrate.<br>
       3. Always plan a project thoroughly before you begin it but be realistic about how much time you can seriously devote to it.</p>
</br>
    <h3 style="font-weight: 600; font-size:14px">Choosing a topic</h3>
    <p>Remember that this course is essentially concerned with the achievement of desired ends. So first identify a real-life problem, then consider it in detail, specify a precise need and then define your design task. As you plan, wherever possible, consider using new materials, techniques and technology such as computer-aided design (CAD).</p>
</br>
    <p>There is nothing wrong with talking to knowledgeable people about your project; in fact, this shows initiative. However, the project is yours so you must do the work yourself.</p>
</br>
    <p>You will need a fairly flexible plan because sometimes resources, apparatus and consumables may not be available when you need them. It is a good idea to work backwards when planning so you know you will meet your final deadline. Finally, when you plan the various stages of your project, give due regard to safety and costs.</p>
</br>
    <h3 style="font-weight: 600; font-size:16px">Question 15-20</h3>
    <p>Look at the Course Guidelines for students on how to approach a design project.</p>
    <p>Complete the sentences below using <strong>NO MORE THAN THREE WORDS</strong> from the passage for each answer.</p>
    <p>Write your answers in boxes 15-20.</p>
    </div>`,
    part: [
      {
        id: 15,
        question: "There are two types of 15............... to choose from.",
        answer: "",
        correctAnswer: "Coursework assignments",
        type: IAnswerType.TEXT_AREA,
        answerList: [],
      },
      {
        id: 16,
        question:
          "A working model must be accompanied by  16...............  of some sort.",
        answer: "",
        correctAnswer: "an Explanation",
        type: IAnswerType.TEXT_AREA,
        answerList: [],
      },
      {
        id: 17,
        question:
          "In order to understand the purpose of the assignment, students are advised to read the 17............... ",
        answer: "",
        correctAnswer: "Syllabus document",
        type: IAnswerType.TEXT_AREA,
        answerList: [],
      },
      {
        id: 18,
        question: "Topics must be based on a 18............... ",
        answer: "",
        correctAnswer: "real-life problem",
        type: IAnswerType.TEXT_AREA,
        answerList: [],
      },
      {
        id: 19,
        question:
          "To avoid handing the assignment in late, it is suggested that students 19...............",
        answer: "",
        correctAnswer: "Work backwards",
        type: IAnswerType.TEXT_AREA,
        answerList: [],
      },
      {
        id: 20,
        question:
          "As well as being cost-effective, the method chosen must also ensure 20............... issues.",
        answer: "",
        correctAnswer: "Safe",
        type: IAnswerType.TEXT_AREA,
        answerList: [],
      },
    ],
  },
];

export const WRITING: ITestsModel[] = [
  {
    id: 1,
    example: `<h1 style="font-weight: 600; font-size:24px">Write about the following topic:</h1>
    </br>
    <p style="font-weight: 500; font-size:16px">Social media is becoming more and more popular with young people these days.</p>
    <p style="font-weight: 400; font-size:16px"> - Why do you think this is? What are the advantages and disadvantages of social media for young people?</p>
    <p style="font-weight: 400; font-size:16px"> - Give reasons for your answer and include any relevant examples from your own knowledge or experience.</p>
    </br>
    <p style="font-weight: 500; font-size:16px">You should spend 40 minutes on this task.</p>`,
    time: 40,
    part: [
      {
        id: 1,
        question: ``,
        answer: "",
        correctAnswer: null,
        type: IAnswerType.EDITOR,
        bigSize: true,
        answerList: [],
      },
    ],
  },
];

export const INTERMEDIATE_TOPIC = {
  LISTENING,
  READING,
  WRITING,
  SPEAKING,
};
