import { ReactNode } from "react";
import { BEGINNER_TOPIC } from "../mocks/beginner";

export enum IAnswerType {
  TEXT = "TEXT",
  TEXT_AREA = "TEXT_AREA",
  EDITOR = "EDITOR",
  CHECKBOX = "CHECKBOX",
  RADIO = "RADIO",
  DROPDOWN = "DROPDOWN",
}

export enum ETestsType {
  LISTENING = "LISTENING",
  READING = "READING",
  WRITING = "WRITING",
  SPEAKING = "SPEAKING",
}

export interface ITestsModel {
  id: number;
  partName?: string | any;
  example: string | any;
  time?: number; // minute
  totalCorrect?: number;
  totalQuestions?: number;
  isDone?: boolean;
  part: {
    id: number;
    question: string | ReactNode | TrustedHTML;
    answer: string | string[];
    correctAnswer: string | string[] | null;
    type: IAnswerType | null;
    bigSize?: boolean;
    answerList: Array<any>;
    combine?: number;
    isCorrect?: boolean;
    numberOfCorrect?: number;
  }[];
}
