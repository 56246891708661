const { localStorage } = window;
const storage = {
  get: (key: string) => {
    return localStorage.getItem(key) || "";
  },
  set: (key: string, value: string) => {
    localStorage.setItem(key, value);
  },

  getObject: (key: string) => {
    return JSON.parse(localStorage.getItem(key) || "null");
  },

  setObject: (key: string, value: unknown) => {
    localStorage.setItem(key, JSON.stringify(value));
  },

  removeStorage: (key: string) => {
    localStorage.removeItem(key);
  },

  clear: () => {
    localStorage.clear();
  },
};

export default storage;
