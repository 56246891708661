import { IAnswerType, ITestsModel } from "../model";

export const LISTENING: ITestsModel[] = [
  {
    id: 1,
    example:
      "Erica and Tim are talking about Tim’s schedule this semester. Listen and choose one true sentence for each item.",
    time: 20,
    part: [
      {
        id: 1,
        question: "",
        answer: "",
        correctAnswer: "Tim is very tired these days.",
        type: IAnswerType.RADIO,
        answerList: [
          "Tim is getting a lot of sleep these days.",
          "Tim is very tired these days.",
        ],
      },
      {
        id: 2,
        question: "",
        answer: "",
        correctAnswer: "Tim is studying a lot.",
        type: IAnswerType.RADIO,
        answerList: ["Tim is studying a lot.", "Tim is working full-time."],
      },
      {
        id: 3,
        question: "",
        answer: "",
        correctAnswer: "Tim is eating one good meal a day.",
        type: IAnswerType.RADIO,
        answerList: [
          "Tim isn’t eating any good meals.",
          "Tim is eating one good meal a day.",
        ],
      },
      {
        id: 4,
        question: "",
        answer: "",
        correctAnswer: "Tim sat outside in the cold last Saturday.",
        type: IAnswerType.RADIO,
        answerList: [
          "Tim played football in the cold last Saturday.",
          "Tim sat outside in the cold last Saturday.",
        ],
      },
      {
        id: 5,
        question: "",
        answer: "",
        correctAnswer: "Tim really needs to get some rest.",
        type: IAnswerType.RADIO,
        answerList: [
          "Tim really needs to get some rest.",
          "Tim needs to call Erica to see how she is.",
        ],
      },
    ],
  },
  {
    id: 2,
    example:
      "Ethan and Lora are talking about the weekend. Listen and choose the correct answers.",
    part: [
      {
        id: 6,
        question: "Where did Lora go last weekend?",
        answer: "",
        correctAnswer: "To her sister’s house",
        type: IAnswerType.RADIO,
        answerList: ["To her friend Angel’s party", "To her sister’s house"],
      },
      {
        id: 7,
        question: "How was the party at Angel’s place?",
        answer: "",
        correctAnswer: "Fun and great",
        type: IAnswerType.RADIO,
        answerList: ["Fun and great", "Not crowded"],
      },
      {
        id: 8,
        question: "What kind of food did Angel serve?",
        answer: "",
        correctAnswer: "Mexican",
        type: IAnswerType.RADIO,
        answerList: ["Mexican", "Brazilian"],
      },
      {
        id: 9,
        question: "Who did Ethan dance with?",
        answer: "",
        correctAnswer: "Angel’s girlfriend",
        type: IAnswerType.RADIO,
        answerList: ["Angel’s girlfriend", "Angel’s sister"],
      },
      {
        id: 10,
        question: "How often does Angel have parties?",
        answer: "",
        correctAnswer: "Once a month",
        type: IAnswerType.RADIO,
        answerList: ["A couple of times a month", "Once a month"],
      },
    ],
  },
  {
    id: 3,
    example:
      "Marie is calling her dentist’s office. Listen to the phone conversation. Choose the correct answers.",
    part: [
      {
        id: 11,
        question: "Marie is calling the dentist to __________.",
        answer: "",
        correctAnswer: "get directions",
        type: IAnswerType.RADIO,
        answerList: ["fix her tooth", "get directions", "make an appointment"],
      },
      {
        id: 12,
        question: "Why does Marie need help?",
        answer: "",
        correctAnswer: "She doesn’t really know the town.",
        type: IAnswerType.RADIO,
        answerList: [
          "She doesn’t really know the town.",
          "She needs to move next week.",
          "She forgot the dentist’s name.",
        ],
      },
      {
        id: 13,
        question: "Marie has __________.",
        answer: "",
        correctAnswer: "a toothache",
        type: IAnswerType.RADIO,
        answerList: ["a toothache", "a loose tooth", "a broken tooth"],
      },
      {
        id: 14,
        question: "On Grove Street, there is __________.",
        answer: "",
        correctAnswer: "a bookstore",
        type: IAnswerType.RADIO,
        answerList: ["a pet shop", "a bookstore", "a supermarket"],
      },
      {
        id: 15,
        question: "Next to the dentist’s office, there is __________.",
        answer: "",
        correctAnswer: "a café",
        type: IAnswerType.RADIO,
        answerList: ["a café", "a big tree", "a mailbox"],
      },
    ],
  },
  {
    id: 4,
    example:
      "Tom and Lin are at a friend’s party. Listen to their conversation. Choose True or False for each sentence.",
    part: [
      {
        id: 16,
        question: "Tom and Lin are meeting for the first time.",
        answer: "",
        correctAnswer: "True",
        type: IAnswerType.DROPDOWN,
        answerList: ["True", "False"],
      },
      {
        id: 17,
        question: "Tom goes to college with Karin.",
        answer: "",
        correctAnswer: "False",
        type: IAnswerType.DROPDOWN,
        answerList: ["True", "False"],
      },
      {
        id: 18,
        question: "Karin is a good student now.",
        answer: "",
        correctAnswer: "True",
        type: IAnswerType.DROPDOWN,
        answerList: ["True", "False"],
      },
      {
        id: 19,
        question: "Lin helps Karin with biology.",
        answer: "",
        correctAnswer: "False",
        type: IAnswerType.DROPDOWN,
        answerList: ["True", "False"],
      },
      {
        id: 20,
        question: "Karin doesn’t play sports in college.",
        answer: "",
        correctAnswer: "False",
        type: IAnswerType.DROPDOWN,
        answerList: ["True", "False"],
      },
    ],
  },
];

export const READING: ITestsModel[] = [
  {
    id: 1,
    example: `<div>
      <span style="font-size: 18px">Read the email. Then choose the correct answers.</span>
      <img src="/assets/reading/Part-1.jpg" style="padding: 24px"/>
      </div>`,
    time: 20,
    part: [
      {
        id: 1,
        question: "What is Sarah doing in Japan?",
        answer: "",
        correctAnswer: "She’s going to school.",
        type: IAnswerType.RADIO,
        answerList: [
          "She’s going to school.",
          "She’s working.",
          "She’s on vacation.",
        ],
      },
      {
        id: 2,
        question: "Where does Keiko live?",
        answer: "",
        correctAnswer: "In Tokyo",
        type: IAnswerType.RADIO,
        answerList: ["In New York", "In Kyoto", "In Tokyo"],
      },
      {
        id: 3,
        question: "Where does Keiko work?",
        answer: "",
        correctAnswer: "At a museum",
        type: IAnswerType.RADIO,
        answerList: ["At an English school", "At a museum", "At a university"],
      },
      {
        id: 4,
        question: "What did Keiko see last week?",
        answer: "",
        correctAnswer: "A traditional show",
        type: IAnswerType.RADIO,
        answerList: [
          "A famous garden",
          "A historic temple",
          "A traditional show",
        ],
      },
    ],
  },
  {
    id: 2,
    example: `<div>
    <span style="font-size: 18px">Read the poster about a food fair in a school. Then choose <strong>True</strong> or <strong>False</strong> for each sentence.</span>
    <img src="/assets/reading/Part-2.jpg" style="padding: 24px"/>
    </div>`,
    part: [
      {
        id: 5,
        question: "The food fair is in the cafeteria.",
        answer: "",
        correctAnswer: "False",
        type: IAnswerType.DROPDOWN,
        answerList: ["True", "False"],
      },
      {
        id: 6,
        question: "Students are cooking a lot of different dishes.",
        answer: "",
        correctAnswer: "True",
        type: IAnswerType.DROPDOWN,
        answerList: ["True", "False"],
      },
      {
        id: 7,
        question: "They’re serving Italian seafood and Mexican pizza.",
        answer: "",
        correctAnswer: "False",
        type: IAnswerType.DROPDOWN,
        answerList: ["True", "False"],
      },
      {
        id: 8,
        question: "You can try rice ice cream at the food fair.",
        answer: "",
        correctAnswer: "False",
        type: IAnswerType.DROPDOWN,
        answerList: ["True", "False"],
      },
      {
        id: 9,
        question:
          "The money from the food fair goes to the International Club.",
        answer: "",
        correctAnswer: "True",
        type: IAnswerType.DROPDOWN,
        answerList: ["True", "False"],
      },
      {
        id: 10,
        question: "The food fair prices are expensive.",
        answer: "",
        correctAnswer: "False",
        type: IAnswerType.DROPDOWN,
        answerList: ["True", "False"],
      },
      {
        id: 11,
        question: "Lunch is only $3.",
        answer: "",
        correctAnswer: "False",
        type: IAnswerType.DROPDOWN,
        answerList: ["True", "False"],
      },
      {
        id: 12,
        question: "The lunch starts at 11:30 p.m.",
        answer: "",
        correctAnswer: "False",
        type: IAnswerType.DROPDOWN,
        answerList: ["True", "False"],
      },
    ],
  },
  {
    id: 3,
    example: `<div>
    <span style="font-size: 18px">Read the article. Then choose  <strong>True</strong> or <strong>False</strong> for each statement.</span>
    <img src="/assets/reading/Part-3.jpg" style="padding: 24px"/>
    </div>`,
    part: [
      {
        id: 13,
        question: "Lisa moved to change jobs.",
        answer: "",
        correctAnswer: "True",
        type: IAnswerType.DROPDOWN,
        answerList: ["True", "False"],
      },
      {
        id: 14,
        question: "Lisa now thinks people in New York are unfriendly.",
        answer: "",
        correctAnswer: "False",
        type: IAnswerType.DROPDOWN,
        answerList: ["True", "False"],
      },
      {
        id: 15,
        question: "Nobody helps Lisa at her office.",
        answer: "",
        correctAnswer: "False",
        type: IAnswerType.DROPDOWN,
        answerList: ["True", "False"],
      },
      {
        id: 16,
        question: "Lisa thinks she’s going to stay in New York.",
        answer: "",
        correctAnswer: "True",
        type: IAnswerType.DROPDOWN,
        answerList: ["True", "False"],
      },
    ],
  },
  {
    id: 4,
    example: `<div>
    <span style="font-size: 18px">Read the blog post.</span>
    <img src="/assets/reading/Part-4.jpg" style="padding: 24px"/>
    </div>`,
    part: [
      {
        id: 17,
        question: "Select the four true statements.",
        answer: "",
        correctAnswer: [
          "A ryokan is an older type of Japanese hotel.",
          "Men and women usually bathe in different baths at the onsen.",
          "There are often natural hot springs near ryokans.",
          "Guests can eat in their rooms.",
        ],
        type: IAnswerType.CHECKBOX,
        answerList: [
          "A ryokan is an older type of Japanese hotel.",
          "Ryokans can be found all over Asia.",
          "Men and women usually bathe in different baths at the onsen.",
          "Guests should wear shoes in the ryokan.",
          "There are often natural hot springs near ryokans.",
          "Guests can eat in their rooms.",
          "The food in ryokans is the same all year round.",
          "There is a special place to eat breakfast in the ryokans.",
        ],
        combine: 4,
      },
    ],
  },
];

export const WRITING: ITestsModel[] = [
  {
    id: 1,
    example: `<p>Complete the sentences below by using the <strong>present continuous</strong> or the <strong>simple past tense</strong>. Use capital letters to start each sentence. Fill in each blank with the appropriate form of the words given in parentheses.</p>

    <p>Example:&nbsp;<strong> A: &nbsp; <u><span style="color:#e74c3c;">Are you learning </span></u>(you / learn) </strong>a new sport this winter?</p>
    
    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; <strong>&nbsp;B: </strong>&nbsp; Yeah, <strong><u><span style="color:#e74c3c;">we’re taking</span></u> (we / take)</strong> a bowling class at the student center.</p>
    `,
    time: 40,
    part: [
      {
        id: 1,
        question: `<div><p><strong>A: (1) ______________ </strong>(your best friend / exercise) enough these days?</p>

        <p><strong>B: </strong>Well, <strong>(2) ______________</strong> (he / walk) to school this semester.</p>
        </div>`,
        answer: "",
        correctAnswer: null,
        type: IAnswerType.TEXT_AREA,
        answerList: [],
      },
      {
        id: 2,
        question: null,
        answer: "",
        correctAnswer: null,
        type: IAnswerType.TEXT_AREA,
        answerList: [],
      },
      {
        id: 3,
        question: `<div><p><strong>A:</strong> What <strong>(3) ______________ </strong>(your parents / do) right now?&nbsp;</p>

          <p><strong>B: (4) ______________</strong> (they / run) in the park.</p>
          </div>`,
        answer: "",
        correctAnswer: null,
        type: IAnswerType.TEXT_AREA,
        answerList: [],
      },
      {
        id: 4,
        question: null,
        answer: "",
        correctAnswer: null,
        type: IAnswerType.TEXT_AREA,
        answerList: [],
      },
      {
        id: 5,
        question: `<div><p><strong>A: (5) ______________ </strong>(you / do) anything fun last night?&nbsp;</p>

          <p><strong>B:</strong> No, <strong>(6) ______________</strong> (I / stay) at home.</p>
          </div>`,
        answer: "",
        correctAnswer: null,
        type: IAnswerType.TEXT_AREA,
        answerList: [],
      },
      {
        id: 6,
        question: null,
        answer: "",
        correctAnswer: null,
        type: IAnswerType.TEXT_AREA,
        answerList: [],
      },
      {
        id: 7,
        question: `<div><p><strong>A:</strong> Where <strong>(7) ______________</strong> (your parents / be) last week?&nbsp;</p>

          <p><strong>B:</strong> <strong>(8) ______________</strong> (they / go) to Thailand and <strong>(9)______________</strong> (have) a great time until <strong>(10) ______________</strong> (my mom / get) sick.</p>
          </div>`,
        answer: "",
        correctAnswer: null,
        type: IAnswerType.TEXT_AREA,
        answerList: [],
      },
      {
        id: 8,
        question: null,
        answer: "",
        correctAnswer: null,
        type: IAnswerType.TEXT_AREA,
        answerList: [],
      },
      {
        id: 9,
        question: null,
        answer: "",
        correctAnswer: null,
        type: IAnswerType.TEXT_AREA,
        answerList: [],
      },
      {
        id: 10,
        question: null,
        answer: "",
        correctAnswer: null,
        type: IAnswerType.TEXT_AREA,
        answerList: [],
      },
      {
        id: 11,
        question: `<div><p><strong>A: (11) ______________ </strong>(you / enjoy) the beach today?&nbsp;</p>

          <p><strong>B:</strong> No, <strong>(12) ______________ </strong>(I / not have) fun because it's too cold and windy.</p>
          </div>`,
        answer: "",
        correctAnswer: null,
        type: IAnswerType.TEXT_AREA,
        answerList: [],
      },
      {
        id: 12,
        question: null,
        answer: "",
        correctAnswer: null,
        type: IAnswerType.TEXT_AREA,
        answerList: [],
      },
      {
        id: 13,
        question: `<div><p>A: Where <strong>(13) ______________</strong> (your brother / go) Saturday night?&nbsp;</p>

          <p>B: <strong>(14) ______________</strong> (he / meet) some friends for dinner.</p>
          </div>`,
        answer: "",
        correctAnswer: null,
        type: IAnswerType.TEXT_AREA,
        answerList: [],
      },
      {
        id: 14,
        question: null,
        answer: "",
        correctAnswer: null,
        type: IAnswerType.TEXT_AREA,
        answerList: [],
      },
      {
        id: 15,
        question: `<div><p><strong>A: (15) ______________</strong> (you / check) your email yesterday?&nbsp;</p>

          <p><strong>B:</strong> No,<strong> (16) ______________</strong> (I / chat) with some friends online instead</p>
          </div>`,
        answer: "",
        correctAnswer: null,
        type: IAnswerType.TEXT_AREA,
        answerList: [],
      },
      {
        id: 16,
        question: null,
        answer: "",
        correctAnswer: null,
        type: IAnswerType.TEXT_AREA,
        answerList: [],
      },
    ],
  },
  {
    id: 2,
    example: ` <div style="font-size: 16px"><p>Complete the conversation with the questions in the box. Use each question only once. There is one extra question.</p>

      <table border="1" cellpadding="1" cellspacing="1">
          <tbody>
              <tr>
                  <td>I mean, where can you hear good music?</td>
              </tr>
              <tr>
                  <td>What do you play?</td>
              </tr>
              <tr>
                  <td>Are you a student?</td>
              </tr>
              <tr>
                  <td>Are you studying law, like Joe?</td>
              </tr>
              <tr>
                  <td>I mean, where do you study?</td>
              </tr>
          </tbody>
      </table>
      
      <p><strong>Joe</strong> &nbsp; Hey, Linda. This is my friend Marco.</p>
      
      <p><strong>Linda</strong> &nbsp; Hi. It’s nice to meet you.</p>
      
      <p><strong>Marco&nbsp;</strong> Nice to meet you.</p>
      
      <p><strong>Joe</strong> &nbsp; Marco’s from Italy.</p>
      
      <p><strong>Linda </strong>&nbsp; Italy? Really? So are you a student here?</p>
      
      <p><strong>Marco</strong>&nbsp; No, I’m just on vacation. How about you?<strong> (17) ___________________</strong></p>
      
      <p><strong>Linda &nbsp; </strong>Yes. Joe and I go to the same school.</p>
      
      <p><strong>Marco&nbsp; </strong>Really? <strong>(18) _____________________</strong></p>
      
      <p><strong>Linda &nbsp; </strong>No, I’m studying music.</p>
      
      <p><strong>Marco&nbsp; </strong>That’s interesting. <strong>(19) ________________________</strong></p>
      
      <p><strong>Linda &nbsp; </strong>The piano.</p>
      
      <p><strong>Marco&nbsp; </strong>Great. So, um, do you ever go to concerts here?<strong> (20) ___________________________</strong></p>
      
      <p><strong>Linda&nbsp; </strong>Oh, there are some great jazz clubs downtown</p>
      </div>`,
    part: [
      {
        id: 17,
        question: "",
        answer: "",
        correctAnswer: null,
        type: IAnswerType.TEXT_AREA,
        answerList: [],
      },
      {
        id: 18,
        question: "",
        answer: "",
        correctAnswer: null,
        type: IAnswerType.TEXT_AREA,
        answerList: [],
      },
      {
        id: 19,
        question: "",
        answer: "",
        correctAnswer: null,
        type: IAnswerType.TEXT_AREA,
        answerList: [],
      },
      {
        id: 20,
        question: "",
        answer: "",
        correctAnswer: null,
        type: IAnswerType.TEXT_AREA,
        answerList: [],
      },
    ],
  },

  {
    id: 3,
    example:
      "Complete the conversations about people’s hobbies and interests. Use the correct form of the verbs in parentheses. Sometimes there is more than one correct answer.",
    part: [
      {
        id: 21,
        question: `<div><p><strong>Example:</strong>&nbsp;</p>

          <p><strong>A:</strong>&nbsp;Do you like to <span style="color:#e74c3c;"><strong><u>read </u></strong></span>(read)?</p>
          
          <p><strong>B:</strong> Actually, I do, but I hate <strong>(21)______________</strong> (read) for school.</p>
          </div>`,
        answer: "",
        correctAnswer: null,
        type: IAnswerType.TEXT_AREA,
        answerList: [],
      },
      {
        id: 22,
        question: `<div><p><strong>A:&nbsp; &nbsp;</strong>Can you <strong>(22) ______________ </strong>(play) a musical instrument?</p>

          <p><strong>B: &nbsp;&nbsp;</strong>Actually, no. But I’m pretty good at <strong>(23) ______________</strong> (read) sheet music.</p>
          </div>`,
        answer: "",
        correctAnswer: null,
        type: IAnswerType.TEXT_AREA,
        answerList: [],
      },
      {
        id: 23,
        question: null,
        answer: "",
        correctAnswer: null,
        type: IAnswerType.TEXT_AREA,
        answerList: [],
      },
      {
        id: 24,
        question: `<div><p><strong>A: &nbsp;</strong> Are you interested in <strong>(24)______________</strong> (learn) Japanese?</p>

          <p><strong>B: </strong>&nbsp;Well, I enjoy<strong> (25)______________</strong> (go) to new countries, but I’m not very good with languages.</p>
          </div>`,
        answer: "",
        correctAnswer: null,
        type: IAnswerType.TEXT_AREA,
        answerList: [],
      },
      {
        id: 25,
        question: null,
        answer: "",
        correctAnswer: null,
        type: IAnswerType.TEXT_AREA,
        answerList: [],
      },
    ],
  },

  {
    id: 4,
    example: `Janice is going on a beach vacation, and her mother gives her some advice. Write the words in the correct order to make suggestions.`,
    part: [
      {
        id: 26,
        question: "(bathing / to / suit / don’t / take / a / forget)",
        answer: "",
        correctAnswer: null,
        type: IAnswerType.TEXT_AREA,
        answerList: [],
      },
      {
        id: 27,
        question: "(should / medicine / pack / you / extra / some)",
        answer: "",
        correctAnswer: null,
        type: IAnswerType.TEXT_AREA,
        answerList: [],
      },
      {
        id: 28,
        question: "(need / light / take / sweater / you / to / a)",
        answer: "",
        correctAnswer: null,
        type: IAnswerType.TEXT_AREA,
        answerList: [],
      },
    ],
  },

  {
    id: 5,
    example: `<div><p>Read the pairs of sentences. Write a sentence with the same meaning using the word in parentheses.</p>

      <p><u><strong>Example: </strong></u>&nbsp;Tara makes a lot of personal calls from work. Jen doesn’t make many.</p>
      
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span style="color:#e74c3c;"><strong><em>Tara makes more personal calls from work than Jen.</em></strong></span> (more)</p>
      </div>`,
    part: [
      {
        id: 29,
        question: `<div><p>I don’t send many text messages. Fred sends a lot of text messages.</p>

          <p><strong>_______________________________________________________ . </strong>(fewer)</p>
          </div>`,
        answer: "",
        correctAnswer: null,
        type: IAnswerType.TEXT_AREA,
        answerList: [],
      },
      {
        id: 30,
        question: `<div><p>Ashley spends a lot of time shopping. Ana doesn’t spend much time shopping.</p>

          <p><strong>_______________________________________________________ .</strong> (more)</p>
          </div>`,
        answer: "",
        correctAnswer: null,
        type: IAnswerType.TEXT_AREA,
        answerList: [],
      },
      {
        id: 31,
        question: `<div><p>I don’t have enough money. Michelle has a lot of money.</p>

          <p><strong>_______________________________________________________ .</strong> (less)</p>
          </div>`,
        answer: "",
        correctAnswer: null,
        type: IAnswerType.TEXT_AREA,
        answerList: [],
      },
    ],
  },
  {
    id: 6,
    example: ` <p>Steve is asking Lina to describe her friend Kate. Write the questions for the answers.</p>

      <p><strong>Example:</strong>&nbsp; <strong>Steve:</strong> <strong><u><span style="color:#e74c3c;">Do Kate and her sister look alike</span> </u></strong>?</p>
      
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>Lina:</strong> No, Kate and her sister look totally different.</p>`,
    part: [
      {
        id: 32,
        question: `<div><p><strong>Steve: _______________________________________________________ </strong>?</p>

          <p><strong>Lina:&nbsp; </strong>She’s tall and thin, and she has long blond hair.</p>
          </div>`,
        answer: "",
        correctAnswer: null,
        type: IAnswerType.TEXT_AREA,
        answerList: [],
      },
      {
        id: 33,
        question: `<div><p><strong>Steve: _______________________________________________________ ?</strong></p>

          <p><strong>Lina:&nbsp;</strong> No, she doesn’t have curly hair.</p>
          </div>`,
        answer: "",
        correctAnswer: null,
        type: IAnswerType.TEXT_AREA,
        answerList: [],
      },
      {
        id: 34,
        question: `<div><p><strong>Steve: _______________________________________________________ </strong>, exactly?</p>

          <p><strong>Lina:</strong>&nbsp; She’s six foot two.</p>
          </div>`,
        answer: "",
        correctAnswer: null,
        type: IAnswerType.TEXT_AREA,
        answerList: [],
      },
    ],
  },

  {
    id: 7,
    example: `<p>Write answers to the questions. Put the adjectives in parentheses in the correct order, and use one or ones. Then choose the correct responses.</p>

      <p><em><strong>Example:&nbsp; &nbsp;</strong></em> &nbsp;</p>
      
      <p><strong>A:</strong> &nbsp; Which car do you like?</p>
      
      <p><strong>B:</strong> &nbsp; I like the <em><u>small red one</u></em>. (red / small)</p>
      
      <p><strong>A:</strong> &nbsp; <span style="color:#e74c3c;"><strong>Me too.</strong></span> / <strong><s>Me neither.</s></strong></p>
      `,
    part: [
      {
        id: 35,
        question: `<div><p><strong>A: </strong>&nbsp; Which phone do you like?</p>

          <p><strong>B:</strong> &nbsp; Most of them, but I don’t like the<strong> (35) ___________________________</strong> . (pink / big)</p>
          
          <p><strong>A:</strong>&nbsp; <strong>(36) Me too. / Me neither.</strong></p>
          </div>`,
        answer: "",
        correctAnswer: null,
        type: IAnswerType.TEXT_AREA,
        answerList: [],
      },
      {
        id: 36,
        question: null,
        answer: "",
        correctAnswer: null,
        type: IAnswerType.TEXT_AREA,
        answerList: [],
      },
      {
        id: 37,
        question: `<div><p><strong>A: &nbsp;</strong> Which shoes would you like?</p>

          <p><strong>B: &nbsp;</strong> I’d like the <strong>(37) ___________________________ .</strong> (Swedish / blue)</p>
          
          <p><strong>A:&nbsp; (38) Me too. / Me neither.</strong></p>
          </div>`,
        answer: "",
        correctAnswer: null,
        type: IAnswerType.TEXT_AREA,
        answerList: [],
      },
      {
        id: 38,
        question: null,
        answer: "",
        correctAnswer: null,
        type: IAnswerType.TEXT_AREA,
        answerList: [],
      },
      {
        id: 39,
        question: `<div class="ckeditor-wrapper" style="margin-bottom: -20px; margin-top: 5px"><p><strong>A: &nbsp;</strong> Which jacket do you want?</p>

          <p><strong>B:</strong> &nbsp; I want the <strong>(39)___________________________ </strong>. (leather / black)</p>
          
          <p><strong>A:&nbsp; (40) I do too. / Neither do I.</strong></p>
          </div>`,
        answer: "",
        correctAnswer: null,
        type: IAnswerType.TEXT_AREA,
        answerList: [],
      },
      {
        id: 40,
        question: null,
        answer: "",
        correctAnswer: null,
        type: IAnswerType.TEXT_AREA,
        answerList: [],
      },
    ],
  },
];

export const SPEAKING: ITestsModel[] = [
  {
    id: 1,
    partName: " Introduction and Interview (4-5 minutes)",
    example: `The examiner will introduce themselves and ask some simple questions about the student’s personal background.`,
    part: [
      {
        id: 1,
        question: "What is your name?",
        answer: "",
        correctAnswer: null,
        type: null,
        answerList: [],
      },
      {
        id: 2,
        question: "How old are you?",
        answer: "",
        correctAnswer: null,
        type: null,
        answerList: [],
      },
      {
        id: 3,
        question: "Where do you live?",
        answer: "",
        correctAnswer: null,
        type: null,
        answerList: [],
      },
      {
        id: 4,
        question: "Who do you live with?",
        answer: "",
        correctAnswer: null,
        type: null,
        answerList: [],
      },
      {
        id: 5,
        question: "What is the name of your school?",
        answer: "",
        correctAnswer: null,
        type: null,
        answerList: [],
      },
      {
        id: 6,
        question: "What is your favorite subject? Why?",
        answer: "",
        correctAnswer: null,
        type: null,
        answerList: [],
      },
      {
        id: 7,
        question: "Do you like your teachers? Why or why not?",
        answer: "",
        correctAnswer: null,
        type: null,
        answerList: [],
      },
      {
        id: 8,
        question: "What do you do in your free time?",
        answer: "",
        correctAnswer: null,
        type: null,
        answerList: [],
      },
      {
        id: 9,
        question: "Do you like watching movies? Why or why not?",
        answer: "",
        correctAnswer: null,
        type: null,
        answerList: [],
      },
      {
        id: 10,
        question: "What is your favorite hobby?",
        answer: "",
        correctAnswer: null,
        type: null,
        answerList: [],
      },
    ],
    time: 5,
  },
  {
    id: 2,
    partName: " Long Turn (1-2 minutes)",
    example:
      "The student will have 1 minute to prepare a short talk on a topic and then speak for up to 2 minutes.",
    part: [
      {
        id: 1,
        question: "Who this friend is?",
        answer: "",
        correctAnswer: null,
        type: null,
        answerList: [],
      },
      {
        id: 2,
        question: "When you met him/her?",
        answer: "",
        correctAnswer: null,
        type: null,
        answerList: [],
      },
      {
        id: 3,
        question: "What you like doing together?",
        answer: "",
        correctAnswer: null,
        type: null,
        answerList: [],
      },
      {
        id: 4,
        question: "And explain why you like this friend.",
        answer: "",
        correctAnswer: null,
        type: null,
        answerList: [],
      },
    ],
    time: 2,
  },
  {
    id: 3,
    partName: " Discussion (4-5 minutes)",
    example:
      "The examiner will ask some questions related to the topic in Part 2, encouraging the student to share more ideas.",
    part: [
      {
        id: 1,
        question: "Why is it important to have friends?",
        answer: "",
        correctAnswer: null,
        type: null,
        answerList: [],
      },
      {
        id: 2,
        question:
          "Do you think children and teenagers make friends easily? Why or why not?",
        answer: "",
        correctAnswer: null,
        type: null,
        answerList: [],
      },
      {
        id: 3,
        question:
          "What activities do teenagers in Vietnam usually do with their friends?",
        answer: "",
        correctAnswer: null,
        type: null,
        answerList: [],
      },
    ],
    time: 4,
  },
];

export const SPEAKING_C3: ITestsModel[] = [
  {
    id: 1,
    partName: "Introduction and Interview (4-5 minutes)",
    example:
      "The examiner will introduce themselves and ask questions about the student’s background and daily life.      ",
    part: [
      {
        id: 1,
        question: `<p><strong>Topic: Personal Information</strong></p>
        <p>What is your full name?<br>
        How old are you?<br>
        What do you like about your hometown?<br>
        Do you live in a house or an apartment?</p>
    
        <p><strong>Topic: School Life</strong></p>
        <p>What is the name of your school?<br>
        What is your favorite subject? Why?<br>
        How do you get to school every day?<br>
        What do you usually do during your school breaks?</p>
    
        <p><strong>Topic: Free Time</strong></p>
        <p>What do you like to do after school?<br>
        Do you prefer indoor or outdoor activities? Why?<br>
        Do you enjoy listening to music? What kind of music do you like?</p>`,
        answer: "",
        correctAnswer: null,
        type: null,
        answerList: [],
      },
    ],
    time: 5,
  },
  {
    id: 2,
    partName: "Long Turn (1-2 minutes)",
    example:
      "The student will have 1 minute to prepare and then speak for up to 2 minutes.",
    part: [
      {
        id: 1,
        question: `<p><strong>Topic Card:</strong></p>
        <p>Describe a teacher you enjoy learning with.</p>
        <p>You should say:</p>
        <p>who this teacher is</p>
        <p>what subject he/she teaches</p>
        <p>what you have learned from him/her</p>
        <p>and explain why you enjoy learning with this teacher.</p>`,
        answer: "",
        correctAnswer: null,
        type: null,
        answerList: [],
      },
    ],
    time: 2,
  },
  {
    id: 3,
    partName: "Discussion (4-5 minutes)",
    example:
      "The examiner will ask follow-up questions related to the topic in Part 2.",
    part: [
      {
        id: 1,
        question: `  <p>Why do you think teachers are important in a student’s life?</p>
        <p>What makes a good teacher, in your opinion?</p>
        <p>How can students show respect to their teachers in Vietnam?</p>
        <p>Do you think teachers and students can be friends? Why or why not?</p>`,
        answer: "",
        correctAnswer: null,
        type: null,
        answerList: [],
      },
    ],
    time: 4,
  },
];
export const SPEAKING_SV: ITestsModel[] = [
  {
    id: 1,
    partName: "Introduction and Interview (4-5 minutes)",
    example:
      "The examiner will introduce themselves and ask questions about the student’s personal background, university life, and daily routines.      ",
    part: [
      {
        id: 1,
        question: `  <p><strong>Topic: Personal Information</strong></p>
        <p>What is your full name?</p>
        <p>Where are you from?</p>
        <p>Can you describe your hometown?</p>
        <p>Do you live with your family or in a dormitory?</p>
        <p><strong>Topic: University Life</strong></p>
        <p>What is the name of your university?</p>
        <p>What are you studying? Why did you choose this subject?</p>
        <p>What do you enjoy most about university life?</p>
        <p>How do you usually get to your university?</p>
        <p><strong>Topic: Free Time</strong></p>
        <p>What do you usually do when you have free time?</p>
        <p>Do you enjoy spending time with friends or alone? Why?</p>
        <p>Are there any clubs or activities you join at university?</p>`,
        answer: "",
        correctAnswer: null,
        type: null,
        answerList: [],
      },
    ],
    time: 5,
  },
  {
    id: 2,
    partName: "Long Turn (1-2 minutes)",
    example:
      "The student will have 1 minute to prepare and then speak for up to 2 minutes.",
    part: [
      {
        id: 1,
        question: `<p><strong>Topic Card:</strong></p>
        <p><strong>Describe a classmate or friend from university.</strong></p>
        <p>You should say:</p>
        <p>who this person is</p>
        <p>how you met</p>
        <p>what you like about this person</p>
        <p>and explain how this person has helped you in your studies or life.</p>`,
        answer: "",
        correctAnswer: null,
        type: null,
        answerList: [],
      },
    ],
    time: 2,
  },
  {
    id: 3,
    partName: "Discussion (4-5 minutes)",
    example:
      "The examiner will ask follow-up questions related to the topic in Part 2.",
    part: [
      {
        id: 1,
        question: `<p><strong>How important is it to make friends at university?</strong></p>
        <p><strong>Do you think students can learn better when they study in groups? Why or why not?</strong></p>
        <p><strong>What are the benefits of being part of a university club or team?</strong></p>
        <p><strong>How can university students balance their studies and social life?</strong></p>`,
        answer: "",
        correctAnswer: null,
        type: null,
        answerList: [],
      },
    ],
    time: 4,
  },
];
export const SPEAKING_WORK: ITestsModel[] = [
  {
    id: 1,
    partName: "Introduction and Interview (4-5 minutes)",
    example:
      "The examiner will introduce themselves and ask questions about the candidate’s personal background, job, and daily routines.",
    part: [
      {
        id: 1,
        question: `<p><strong>Topic: Personal Information</strong></p>
        <p>What is your name?</p>
        <p>Where are you from?</p>
        <p>Can you describe your hometown?</p>
        <p>Do you live alone or with your family?</p>
      
        <p><strong>Topic: Job</strong></p>
        <p>What is your job?</p>
        <p>Where do you work?</p>
        <p>How long have you been doing this job?</p>
        <p>What do you like about your job?</p>
      
        <p><strong>Topic: Daily Routine</strong></p>
        <p>What is your daily routine like?</p>
        <p>Do you enjoy your daily schedule? Why or why not?</p>
        <p>What do you do after work?</p>`,
        answer: "",
        correctAnswer: null,
        type: null,
        answerList: [],
      },
    ],
    time: 5,
  },
  {
    id: 2,
    partName: "Long Turn (1-2 minutes)",
    example:
      "The candidate will have 1 minute to prepare and then speak for up to 2 minutes.",
    part: [
      {
        id: 1,
        question: `<p><strong>Topic Card:</strong></p>
        <p>Describe your first day at your current job.</p>
        <p>You should say:</p>
        <p>- when and where it was</p>
        <p>- what happened on that day</p>
        <p>- how you felt</p>
        <p>- and explain why you remember this day.</p>`,
        answer: "",
        correctAnswer: null,
        type: null,
        answerList: [],
      },
    ],
    time: 2,
  },
  {
    id: 3,
    partName: "Discussion (4-5 minutes)",
    example:
      "The examiner will ask follow-up questions related to the topic in Part 2.",
    part: [
      {
        id: 1,
        question: `<p>How do people usually prepare for their first day at work?</p>
        <p>Do you think teamwork is important in the workplace? Why?</p>
        <p>What are the main challenges of working life in Vietnam?</p>
        <p>How can people balance work and personal life?</p>`,
        answer: "",
        correctAnswer: null,
        type: null,
        answerList: [],
      },
    ],
    time: 4,
  },
];

export const BEGINNER_TOPIC = {
  LISTENING,
  READING,
  WRITING,
  SPEAKING,
};

export const SPEAKING_DROPDOWN = [
  {
    label: "Học sinh cấp 2",
    value: SPEAKING,
  },
  {
    label: "Học sinh cấp 3",
    value: SPEAKING_C3,
  },
  {
    label: "Sinh viên",
    value: SPEAKING_SV,
  },
  {
    label: "Người đi làm",
    value: SPEAKING_WORK,
  },
];
