import { STORAGE } from 'constants/storage.const';
import LayoutAdmin from 'layout/admin/LayoutAdmin';
import LayoutClient from 'layout/client/LayoutClient';
import { FC } from 'react';
import storage from 'utils/LocalStorage';

export interface IProps {
  children: JSX.Element;
}
const LayoutResult: FC<IProps> = ({ children }) => {
  const role = storage.get(STORAGE.ROLES);

  if (role === 'STUDENT') {
    return <LayoutClient children={children} />;
  }

  return <LayoutAdmin />;
};

export default LayoutResult;
