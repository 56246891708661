import { STORAGE } from "constants/storage.const";
import { Suspense, useLayoutEffect } from "react";
import { useRoutes } from "react-router-dom";
import storage from "utils/LocalStorage";
import "./App.css";
import { routes } from "./routes/routes";

const App = () => {
  const element = useRoutes(routes);
  useLayoutEffect(() => {
    const tokenLocal = storage.get(STORAGE.TOKEN);
    if (tokenLocal) {
      storage.set(STORAGE.TOKEN, tokenLocal);
    }
  }, []);
  return (
    <Suspense fallback={<div />}>
      <>{element}</>
    </Suspense>
  );
};

export default App;
