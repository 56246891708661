import { Button, Layout, Menu, MenuProps, theme } from 'antd';
import { STORAGE } from 'constants/storage.const';
import { QUIZ_TEST } from 'pages/Tests/mocks/quiz';
import { FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import storage from 'utils/LocalStorage';

const { Header, Content, Sider } = Layout;

export interface IProps {
  children: JSX.Element;
}

const items2: MenuProps['items'] = [
  { key: '/tests', label: <p className="text-black font-semibold">Tests</p> },
  {
    key: '/result/',
    label: <p className="text-black font-semibold">Test Results</p>,
    children: [
      {
        key: QUIZ_TEST?.[0]?.code,
        label: (
          <p className="text-black font-semibold">{QUIZ_TEST?.[0]?.title}</p>
        ),
        // type: "group",
      },
      {
        key: QUIZ_TEST?.[1]?.code,
        label: (
          <p className="text-black font-semibold">{QUIZ_TEST?.[1]?.title}</p>
        ),
        // type: "group",
      },
    ],
  },
];

const LayoutClient: FC<IProps> = ({ children }) => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const navigate = useNavigate();

  const userId = storage.get(STORAGE.USER_ID);

  const onClickMenu = (data: any) => {
    if (data) {
      const { key, keyPath } = data;
      if (keyPath?.length === 2) {
        navigate(`${keyPath[1]}${userId}?testCode=${keyPath[0]}`);
      } else if (keyPath?.length === 1) {
        navigate(`${key}`);
      }
    }
  };

  return (
    <Layout style={{ height: '100vh' }}>
      <Header
        style={
          {
            // backgroundColor: '#F9F9F9',
          }
        }
        className="w-full flex items-center justify-between bg-white"
      >
        {/* <div>
          <a className="font-semibold text-lg" href="/tests">Tests</a>
        </div> */}
        <Menu
          // mode="inline"
          mode="horizontal"
          defaultSelectedKeys={['/tests']}
          className="bg-white"
          defaultOpenKeys={['sub1']}
          style={{ height: '100%', borderRight: 0, width: '200px' }}
          items={items2}
          onClick={onClickMenu}
        />
        <Link to="/tests">
          <img
            width={80}
            height={70}
            alt=""
            src="/assets/logo.png"
          />
        </Link>

        <Button
          type="primary"
          onClick={() => {
            navigate('/login');
            storage.clear();
          }}
          className="text-black hover:!text-black"
        >
          Logout
        </Button>
      </Header>
      <Layout style={{ padding: '0 24px 24px' }}>
        {/* <Sider
          width={200}
          style={{
            background: colorBgContainer,
            marginTop: "18px",
            marginLeft: "-24px",
          }}
        >
          <Menu
            mode="inline"
            defaultSelectedKeys={["/tests"]}
            defaultOpenKeys={["sub1"]}
            style={{ height: "100%", borderRight: 0 }}
            items={items2}
            onClick={onClickMenu}
          />
        </Sider> */}
        <Content
          style={{
            padding: 24,
            margin: '16px 0  0 0',
            minHeight: 'fit-content',
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default LayoutClient;
