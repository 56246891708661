import { lazy } from 'react';
import componentLoader from '../helpers/loader.helper';

// HOME
export const AsyncHomePage = lazy(() =>
  componentLoader(() => import('pages/Home'))
);
// Login
export const AsyncLoginPage = lazy(() =>
  componentLoader(() => import('pages/Login'))
);
// Login
export const AsyncRegisterPage = lazy(() =>
  componentLoader(() => import('pages/Register')),
);

// NOT FOUND
export const AsyncNotFoundPage = lazy(() =>
  componentLoader(() => import('pages/NotFound'))
);

/**
 * Tests
 * */
export const AsyncTestsPage = lazy(() =>
  componentLoader(() => import('pages/Tests'))
);
export const AsyncTestsDetailPage = lazy(() =>
  componentLoader(() => import('pages/Tests/pages/Detail'))
);
export const AsyncQuizPage = lazy(() =>
  componentLoader(() => import('pages/Tests/pages/Quiz'))
);

/**
 * TestResults
 */

export const AsyncTestResultsEditPage = lazy(() =>
  componentLoader(() => import('pages/TestResults/Edit'))
);
export const AsyncTestResultsPage = lazy(() =>
  componentLoader(() => import('pages/TestResults/List'))
);
export const AsyncViewSpeakingPage = lazy(() =>
  componentLoader(() => import('pages/TestResults/View/Speaking'))
);
export const AsyncViewWritingPage = lazy(() =>
  componentLoader(() => import('pages/TestResults/View/Writing'))
);
export const AsyncResultPage = lazy(() =>
  componentLoader(() => import('pages/Result'))
);


